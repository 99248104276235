* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  text-align: center;
  background: #b5b5b5;
  background: -webkit-linear-gradient(45deg, to right, #9bc5c3, #b5b5b5);
  background: linear-gradient(45deg, to right, #9bc5c3, #b5b5b5);
  font-family: Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif;
  font-size: 1.5em;
}

#main-wrapper {
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

main {
  width: 50%;
  min-width: 300px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wrapper {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 1em;
}

p {
  margin: .5em;
}

h1, h2 {
  margin: 0 0 .2em;
}

.hint {
  color: #363636;
  font-size: 1rem;
  font-style: italic;
}

input[type="text"] {
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 4px;
  margin: .2em auto 0;
  padding: .2em;
  font-size: 1.5em;
  display: block;
}

.result {
  background-color: #c5a59b;
  border-radius: 4px;
  padding: 1em;
}

/*# sourceMappingURL=index.69144074.css.map */
